import React, { FunctionComponent } from "react"
import styled from "styled-components"
import Breadcrumb from "./Breadcrumb"
import { GatsbyImage } from "gatsby-plugin-image"

import { Button } from "./Buttons/Buttons"
import BackArrow from "../assets/svg/long-arrow-left.svg"
import { Link } from "gatsby"
import { Container } from "@material-ui/core"

import IconButton from "@material-ui/core/IconButton"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import InstagramIcon from "@material-ui/icons/Instagram"
import YoutubeIcon from "@material-ui/icons/YouTube"
import VimeoIcon from "../assets/svg/Vimeo.svg"
import WebIcon from "@material-ui/icons/Language"
import { makeStyles } from "@material-ui/core"

interface PartnerHeaderI {
  title: string
  intro: string
  catUrl: string
  tags: string[]
  img: any
  breadcrumb: IBreadcrumb
  instagram?: string
  linked?: string
  youtube?: string
  vimeo?: string
  website?: string
}
interface Item {
  title: string
  url: string
}
type IBreadcrumb = Array<Item>
export const PartnerHeader: FunctionComponent<PartnerHeaderI> = ({
  title,
  intro,
  catUrl,
  tags,
  img,
  breadcrumb,
  instagram,
  linked,
  youtube,
  vimeo,
  website,
}) => {
  const useStyles = makeStyles(theme => ({
    customHoverFocus: {
      width: 35,
      height: 35,
      "&:hover, &.Mui-focusVisible": { backgroundColor: "#2e2e2e" },
      backgroundColor: "black",
      color: "",
      marginRight: 8,
    },
    icon: {
      width: 20,
      height: 20,
    },
  }))

  const classes = useStyles()

  return (
    <FullWidthContainer>
      <Container maxWidth={"lg"}>
        <Breadcrumb data={breadcrumb} />
      </Container>

      <ImgContainer>
        <GatsbyImage image={img} className={"img"} />
      </ImgContainer>
      <Container maxWidth={"lg"}>
        <Box>
          <BackLink to={"/partnerships/"}>
            <BackArrow className={"image"} />
          </BackLink>

          <h1>{title}</h1>
          <PartnerIntro>{intro}</PartnerIntro>
          <a href={catUrl} target="blank">
            <Button size={1} customwidth={"40%"}>
              Learn More{" "}
            </Button>
          </a>

          <TagContainer>
            {tags ? tags.map((item, i) => {
              return <Tag>{item}</Tag>
            }):(null)}
          </TagContainer>
          <SocialIcons>
            {instagram && (
              <StyledIconButton
                aria-label="Instagram"
                href={instagram}
                target="_blank"
                className={classes.customHoverFocus}
              >
                <InstagramIcon className={classes.icon} />
              </StyledIconButton>
            )}
            {linked && (
              <StyledIconButton
                aria-label="LinkedIn"
                href={linked}
                target="_blank"
                className={classes.customHoverFocus}
              >
                <LinkedInIcon className={classes.icon} />
              </StyledIconButton>
            )}
            {youtube && (
              <StyledIconButton
                aria-label="Youtube"
                href={youtube}
                target="_blank"
                className={classes.customHoverFocus}
              >
                <YoutubeIcon className={classes.icon} />
              </StyledIconButton>
            )}
            {website && (
              <StyledIconButton
                aria-label="Website"
                href={website}
                target="_blank"
                className={classes.customHoverFocus}
              >
                <WebIcon className={classes.icon} />
              </StyledIconButton>
            )}
            {vimeo && (
              <StyledIconVimeoButton
                aria-label="Vimeo"
                href={vimeo}
                target="_blank"
                //className={classes.customHoverFocus}>
              >
                <VimeoIconDiv />
              </StyledIconVimeoButton>
            )}
          </SocialIcons>
        </Box>
      </Container>
    </FullWidthContainer>
  )
}

const FullWidthContainer = styled.div`
  width: 100%;
`
const ImgContainer = styled.div`
  height: 740px;
  overflow: hidden;
  &img {
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      object-fit: fill;
    }
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -650px;
  margin-bottom: 70px;
  max-width: 560px;
  max-height: 600px;
  padding: 48px;
  background-color: black;
  border-top: 4px solid white;
  color: white;
  > h1 {
    color: white;
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 0px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: -550px;
    margin-bottom: 0px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 0px;
    padding: 40px 20px;
    margin-top: -600px;
  }
`
const BackLink = styled(Link)`
  height: 40px;
  width: 100px;
  object-fit: fill;
  .image {
    height: 50px;
    width: 120px;
    object-fit: fill;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 90px;
    }
  }
`

const PartnerIntro = styled.p`
  color: white;
  margin-bottom: 24px;
  text-transform: uppercase;
`

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 24px;
`
const Tag = styled.b`
  text-align: center;
  text-transform: uppercase;
  border: 1px solid white;
  font-size: 0.8rem;
  margin: 5px;
  line-height: 14px;
  height: 20px;
  width: auto;
  padding: 1px 8px 0px;
  border-radius: 10px;
`
const SocialIcons = styled.div`
  flex-direction: row;
  margin-top: 10px;
`
const StyledIconButton = styled(IconButton)`
  background-color: white !important;
  color: black;
  margin: 6px;
  overflow: visible;
  .root {
    background-color: white !important;
  }
`
const StyledIconVimeoButton = styled(IconButton)`
  background-color: white !important;
  color: black;
  margin: 4px;
  width: 35px;
  height: 35px;
  padding: 8px;

  .root {
    background-color: white !important;
  }
`
const VimeoIconDiv = styled(VimeoIcon)`
  color: black;
  background-color: black;
  border-radius: 50%;
  height: 20px;
  width: 20px !important;
  padding: 2px;
`

import React from "react"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import styled from "styled-components"
import { Layout } from "../components/layout"
import { graphql } from "gatsby"
import { PartnerHeader } from "../components/PartnerHeader"
import { LazyBlockFactory } from "../util/LazyBlockFactory"

import { Container } from "@material-ui/core"
import { InstagramFeed } from "../components/InstagramFeed"

const PartnerTemplate = ({ data: { previous, next, wpPartner, allWpPartner } }) => {
  const partners = allWpPartner.edges.map(v => v.node)

  const breadcrumbData = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Partnerships",
      url: "/partnerships",
    },
    {
      title: wpPartner.title,
      url: "/partnerships/" + wpPartner.slug,
    },
  ]
  const blocks = LazyBlockFactory(wpPartner.lazy_data, wpPartner.title)

  let headerImage = null

  try {
    headerImage = wpPartner.PartnerInfo.heroImage.localFile.childImageSharp.gatsbyImageData
  } catch (e) {
    console.error(e)
    console.error("Partner Header image missing.")
  }

  return (
    <Layout partners={partners} title={"Graduate Fashion Week - " + wpPartner.title}>
      <PartnerHeader
        breadcrumb={breadcrumbData}
        title={wpPartner.title}
        intro={wpPartner.PartnerInfo.partnershipIntro}
        catUrl={wpPartner.PartnerInfo.mainCallToAction}
        tags={wpPartner.PartnerInfo.tags}
        img={headerImage}
        instagram={wpPartner.PartnerInfo.instagram}
        youtube={wpPartner.PartnerInfo.youtubeChannel}
        vimeo={wpPartner.PartnerInfo.vimeo}
        website={wpPartner.PartnerInfo.website}
        linked={wpPartner.PartnerInfo.linkedin}
      />
      <Container maxWidth={"lg"} style={{ zIndex: 1 }}>
        <div
          style={{
            backgroundColor: "white",
            // marginTop: -30,
            // border: "1px solid red",
            overflow: "hidden",
            zIndex: 1,
          }}
        >
          {blocks.map(v => v)}
        </div>
      </Container>
      <InstagramFeed />
    </Layout>
  )
}

export default PartnerTemplate

const FullWidth = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

export const pageQuery = graphql`
  query partnerById($id: String!) {
    wpPartner(id: { eq: $id }) {
      id
      title
      slug
      lazy_data
      PartnerInfo {
        tags
        instagram
        linkedin
        youtubeChannel
        website
        vimeo

        partnershipIntro
        mainCallToAction
        partnershipLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, layout: CONSTRAINED)
            }
          }
        }
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allWpPartner {
      edges {
        node {
          id
          title
          slug
          PartnerInfo {
            tags

            partnershipIntro
            partnershipLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
